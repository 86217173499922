import { render, staticRenderFns } from "./addClosed.vue?vue&type=template&id=057a2d94&"
import script from "./addClosed.vue?vue&type=script&lang=js&"
export * from "./addClosed.vue?vue&type=script&lang=js&"
import style0 from "./addClosed.vue?vue&type=style&index=0&id=057a2d94&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports